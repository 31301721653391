import { fetchMutation } from 'Util/Request/Mutation';

import MollieProcessTransactionQuery from '../query/MollieProcessTransaction.query';
import { getPaymentData, deletePaymentData } from '../util/PaymentDataPersistence';

const updateInitialCartData = async (args, callback, instance) => {
    const paymentData = getPaymentData();

    if (!paymentData?.status && location.pathname.indexOf('mollie/checkout/response') === -1) {
        return callback(...args);
    } else if (
        paymentData?.status
        && location.pathname.indexOf('onestepcheckout') === -1
        && location.pathname.indexOf('mollie/checkout/response') === -1
    ) {
        await fetchMutation(MollieProcessTransactionQuery.process(paymentData.payment_token, true));
        deletePaymentData();

        return callback(...args);
    }
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData
        }
    }
};
